// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/services/api'
const API_BASE_URL = `${process.env.REACT_APP_API_URL}`

export const updateAlert = createAsyncThunk('dashboardCliente/updateAlert', async (props, { dispatch, getState }) => {
  return {text:props.text, variant:props.variant}
})

export const setAlertTrigger = createAsyncThunk('dashboardCliente/setAlertTrigger', async (toggle, { dispatch, getState }) => {
  return toggle
})

export const getData = createAsyncThunk('dashboardCliente/getData', async params => {
  const licenca = JSON.parse(localStorage.getItem("userData"))
    
  const response = await axios.get(`${API_BASE_URL}api/dashboardcliente/tabelapacientes?clientId=${licenca.clientId}&offset=${params?.page}&limit=${params?.perPage}`)

  return {
    params,
    data: response.data.paciente,
    totalPages: response.data.ordenate.totalPage
  }
})

export const getGraficoStatus = createAsyncThunk('dashboardCliente/getGraficoStatus', async params => {
  const response = await axios.get(`${API_BASE_URL}api/dashboardcliente/graficostatuspaciente?firstDate=${params}`)
  
  return {
    datas: response.data.datas,
    evaluation: response.data.avaliacao,
    rehab: response.data.reabilitacao
  }
})

export const getStatusReabilitacao = createAsyncThunk('dashboardCliente/getStatusReabilitacao', async params => {
  const licenca = JSON.parse(localStorage.getItem("userData"))

  const response = await axios.get(`${API_BASE_URL}api/dashboardcliente/graficostatusreabilitacao?clientId=${licenca.clientId}`)
  return {
    data: response.data
  }
}) 

export const getConfiguracao = createAsyncThunk('dashboardCliente/getConfiguracao', async params => {
  const licenca = JSON.parse(localStorage.getItem("userData"))
  
  const response = await axios.get(`${API_BASE_URL}api/configuracaocliente/search?clientId=${licenca.clientId}`)
  
  return {
    data: response.data.configuracao_cliente[0]
  }
})

export const getComunicacao = createAsyncThunk('dashboardCliente/getComunicacao', async params => {
  const licenca = JSON.parse(localStorage.getItem("userData"))
  
  const response = await axios.get(`${API_BASE_URL}api/conversa/comunicacaodashboard?clientId=${licenca.clientId}`)
  
  return {
    data: response.data.conversa_aggregate.aggregate.count
  }
})

export const updateConfiguracao = createAsyncThunk('dashboardCliente/updateConfiguracao', async params => {

  const response = await axios.put(`${API_BASE_URL}api/configuracaocliente/update?uuid=${params.uuid}`, params.obj)
})

export const postMensagem = createAsyncThunk('dashboardCliente/postMensagem', async obj => {
  const response = await axios.post(`${API_BASE_URL}api/mensagem/create`, obj)
})

export const postConversa = createAsyncThunk('dashboardCliente/postConversa', async (obj) => {
  const response = await axios.post(`${API_BASE_URL}api/conversamultiplo/create`, obj)
  return response
})

export const getConversaNavbar = createAsyncThunk('dashboardCliente/getConversaNavbar', async params => {
  const licenca = JSON.parse(localStorage.getItem("userData"))
  let response

  if (licenca.usuarioMaster === false) {
    response = await axios.get(`${API_BASE_URL}api/conversa/navbarmensagem?clientId=${licenca.clientId}&medicoId=${licenca.uuid}`)
  } else {
    response = await axios.get(`${API_BASE_URL}api/conversa/navbarmensagemmaster?clientId=${licenca.clientId}`)
  }

  return {
    data: response.data.conversa,
    quantidade: response.data.conversa_aggregate.aggregate.count
  }
})

export const getPacienteNavbar = createAsyncThunk('dashboardCliente/getPacienteNavbar', async params => {
  const licenca = JSON.parse(localStorage.getItem("userData"))
  
  const response = await axios.get(`${API_BASE_URL}api/paciente/navbarpacientemaster?clientId=${licenca.clientId}&date=${params.data}`)
  
  return {
    data: response.data.paciente,
    quantidade: response.data.ordenate.totalItem
  }
})

export const getTicketNavbar = createAsyncThunk('dashboardCliente/getTicketNavbar', async params => {
  const licenca = JSON.parse(localStorage.getItem("userData"))
  
  let response
  if (licenca.usuarioMaster === false) {
    response = await axios.get(`${API_BASE_URL}api/ticketsuporte/navbarticket?clientId=${licenca.clientId}&medicoId=${licenca.uuid}&date=${params.data}`)
  } else {
    response = await axios.get(`${API_BASE_URL}api/ticketsuporte/navbarticketmaster?clientId=${licenca.clientId}&date=${params.data}`)
  }
  
  return {
    data: response.data.ticket,
    quantidade: response.data.ordenate.totalItem
  }
})

export const deletePaciente = createAsyncThunk('dashboardCliente/deletePaciente', async (uuid, { dispatch, getState }) => {
    const response = await axios.put(`${API_BASE_URL}api/paciente/delete?uuid=${uuid}`)
      return response
})
  
export const updateMails = createAsyncThunk(
  'appEmail/updateMails',
  async ({ emailIds, dataToUpdate }, { dispatch, getState }) => {
  
  }
)

export const updateMailLabel = createAsyncThunk(
  'appEmail/updateMailLabel',
  async ({ emailIds, label }, { dispatch, getState }) => {
    const response = await axios.post('/apps/email/update-emails-label', { emailIds, label })
    await dispatch(getMails(getState().email.params))
    return response.data
  }
)

export const paginateMail = createAsyncThunk('appEmail/paginateMail', async ({ dir, emailId }) => {
  const response = await axios.get('/apps/email/paginate-email', { params: { dir, emailId } })
  return response.data
})

export const selectCurrentMail = createAsyncThunk('dashboardCliente/selectCurrentMail', async uuid => {
  const licenca = JSON.parse(localStorage.getItem("userData"))
  const response = await axios.get(`${API_BASE_URL}api/conversa/searchmedicodashboard?search=${uuid}&clientId=${licenca.clientId}`)
  return {
    data: response.data.conversa[0].mensagens
  }
})

export const appEmailSlice = createSlice({
  name: 'dashboardCliente',
  initialState: {
    data: [],
    emailsMeta: {},
    statusRehab: {},
    config: {},
    comunica: 0,
    dataGrafic: [],
    avaliacaoGrafic: [],
    reabilitacaoGrafic: [],
    total: 1,
    mensagemCount: 0,
    mensagemHeader: [],
    pacienteCount: 0,
    pacienteHeader: [],
    ticketCount: 0,
    ticketHeader: [],
    params: {},
    selectedMails: [],
    currentMail: null,
    alertProps:{text:"", variant:""},
    alertTrigger:false,
    postToggle:false,
    testeDelete:null
  },
  extraReducers: builder => {
    builder 
      .addCase(getData.fulfilled, (state, action) => {
        state.params = action.payload.params 
        state.total = action.payload.totalPages
        state.data = action.payload.data
      })
      .addCase(getConversaNavbar.fulfilled, (state, action) => {
        state.mensagemHeader = action.payload.data
        state.mensagemCount = action.payload.quantidade        
      })
      .addCase(getPacienteNavbar.fulfilled, (state, action) => {
        state.pacienteHeader = action.payload.data
        state.pacienteCount = action.payload.quantidade        
      })
      .addCase(getTicketNavbar.fulfilled, (state, action) => {
        state.ticketHeader = action.payload.data
        state.ticketCount = action.payload.quantidade        
      })
      .addCase(getGraficoStatus.fulfilled, (state, action) => {
        state.dataGrafic = action.payload.datas
        state.avaliacaoGrafic = action.payload.evaluation
        state.reabilitacaoGrafic = action.payload.rehab
      })
      .addCase(getConfiguracao.fulfilled, (state, action) => {
        state.config = action.payload.data
      })
      .addCase(getComunicacao.fulfilled, (state, action) => {
        state.comunica = action.payload.data
      })
      .addCase(getStatusReabilitacao.fulfilled, (state, action) => {
        state.statusRehab = action.payload.data
      })
      .addCase(updateAlert.fulfilled, (state, action) => {
        state.alertProps = action.payload
      })
      .addCase(setAlertTrigger.fulfilled, (state, action) => {
        state.alertTrigger = action.payload
      })
      .addCase(updateMails.fulfilled, (state, action) => {
        function updateMailData(email) {
          Object.assign(email, action.payload.dataToUpdate)
        }
        state.mails.forEach(email => {
          if (action.payload.emailIds.includes(email.id)) {
            updateMailData(email)
          }
        })
      })
      .addCase(paginateMail.fulfilled, (state, action) => {
        const data = action.payload
        const dataIndex = state.mails.findIndex(i => i.id === data.id)
        dataIndex === 0 ? (data.hasPreviousMail = false) : (data.hasPreviousMail = true)
        dataIndex === state.mails.length - 1 ? (data.hasNextMail = false) : (data.hasNextMail = true)
        state.currentMail = data
      })
      .addCase(selectCurrentMail.fulfilled, (state, action) => {
        state.currentMail = action.payload
      })
  }
})

export const { selectMail, selectAllMail, resetSelectedMail } = appEmailSlice.actions

export default appEmailSlice.reducer
