// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/services/api'
const API_BASE_URL = `${process.env.REACT_APP_API_URL}`

export const getAllData = createAsyncThunk('appUsers/getAllData', async () => {
  const response = await axios.get('/api/users/list/all-data')
  return response.data
})

export const getModelos = createAsyncThunk('appUsers/getModelos', async params => {
  const response = await axios.get(`${API_BASE_URL}api/modeloavaliacao/list?offset=0&limit=1000&orderBy=nome::asc`, {
    withCredentials: true
  })
  return {
    params,
    data: response.data.modelo_avaliacao,
    totalPages: response.data.ordenate.totalPage
  }
})

export const getPacientes = createAsyncThunk('appUsers/getPacientes', async params => {
  const licenca = JSON.parse(localStorage.getItem("userData"))

  const response = await axios.get(`${API_BASE_URL}api/paciente/list?offset=0&limit=1000&clientId=${licenca.clientId}&orderBy=nome::asc`, {
    withCredentials: true
  })
  return {
    params,
    data: response.data.pacientes,
    totalPages: response.data.ordenate.totalPage
  }
})

export const getModeloDetail = createAsyncThunk('appUsers/getModeloDetail', async (modeloId) => {
  const response = await axios.get(`${API_BASE_URL}api/modeloavaliacao/search?search=${modeloId}`)
  const newResponse = response.data.modelo_avaliacao[0]
  for (let i = 0; i < newResponse.grupo_perguntas.length; i++) {
    const grupo = newResponse.grupo_perguntas[i]
    for (let j = 0; j < grupo.perguntas.length; j++) {
      const pergunta = grupo.perguntas[j]

      // *** Esses campos não existem no banco de dados, são calculados em "tempo de execução"
      //1-Sim possui imagem, 2-Não possui imagem
      newResponse.grupo_perguntas[i].perguntas[j].temImagem = typeof newResponse.grupo_perguntas[i].perguntas[j].imagem === 'string' ? '1' : '2'

      //Tipo de Resposta
      //newResponse.grupo_perguntas[i].perguntas[j].tipoDeResposta = newResponse?.grupo_perguntas[i]?.perguntas[j]?.respostas[0]?.tipoDeResposta
    }
  }
  return newResponse
})

export const getData = createAsyncThunk('appUsers/getData', async params => {
  const licenca = JSON.parse(localStorage.getItem("userData"))
  const response = await axios.get(`${API_BASE_URL}api/avaliacaopaciente/list?offset=${params?.page}&limit=${params?.perPage}&clientId=${licenca.clientId}&orderBy=criadoEm::desc`, {
    withCredentials: true
  })
  return {
    params,
    data: response.data.avaliacao_paciente,
    totalPages: response.data.ordenate.totalItem
  }
})

export const getAvaliacaoSelected = createAsyncThunk('appUsers/getAvaliacaoSelected', async avaliacao => {
  return avaliacao
})

export const setAlertTrigger = createAsyncThunk('appCustomers/setAlertTrigger', async (toggle, { dispatch, getState }) => {
  return toggle
})

export const updateAlert = createAsyncThunk('appUsers/updateAlert', async (props, { dispatch, getState }) => {
  return { text: props.text, variant: props.variant }
})

export const getUser = createAsyncThunk('appUsers/getUser', async id => {
  const response = await axios.get('/api/users/user', { id })
  return response.data.user
})

export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

export const viewPatientEvaluation = createAsyncThunk('appUsers/viewPatientEvaluation', async (id) => {
  const { clientId } = JSON.parse(localStorage.getItem("userData"))
  const params = new URLSearchParams({ id, clientId })
  const response = await axios.get(`${API_BASE_URL}api/avaliacaopaciente/view?${params.toString()}`)
  return response
})

export const searchEvaluationByPatientName = createAsyncThunk('appUsers/searchEvaluationByPatientName', async (patientName) => {
  const licenca = JSON.parse(localStorage.getItem("userData"))
  const res = await axios.get(`${API_BASE_URL}api/avaliacaopaciente/searchEvaluationByPatientName?clientId=${licenca.clientId}&search=${patientName}`)
  return res.data.avaliacao_paciente
})

export const addAvaliaçãoPaciente = createAsyncThunk('appUsers/addAvaliaçãoPaciente', async (avaliacao, { dispatch, getState }) => {
  const response = await axios.post(`${API_BASE_URL}api/avaliacaopaciente/create`, avaliacao)
  await dispatch(getData(getState()))
  return response.data.uuid
})

export const updateCurrentStep = createAsyncThunk('appUsers/updateCurrentStep', async (currentStep, { dispatch, getState }) => {
  return currentStep
})

export const modalOpen = createAsyncThunk('appUsers/modalOpen', async params => {
  return params
})

export const confereAtivos = createAsyncThunk('appUsers/confereAtivos', async params => {
  const licenca = JSON.parse(localStorage.getItem("userData"))

  const response = await axios.get(`${API_BASE_URL}api/avaliacaopaciente/confereAtivos?clientId=${licenca.clientId}`)
  
  return {
    params,
    data: response?.data?.avaliacao_paciente_aggregate?.aggregate?.count
  }
})

export const deleteAvaliacao = createAsyncThunk('appUsers/deleteAvaliacao', async (uuid, { dispatch, getState }) => {
  await axios.put(`${API_BASE_URL}api/avaliacaopaciente/delete?uuid=${uuid}`)
  await dispatch(getAvaliacaoSelected({}))
  await dispatch(getData(getState()))
})

export const updateAvaliacao = createAsyncThunk('appAvaliacao/updateAvaliacao', async (objeto, { dispatch, getState }) => {
  const uuid = objeto.avaliacaoUuid
  
  await axios.put(`${API_BASE_URL}api/avaliacaopaciente/update?uuid=${uuid}`, objeto.avaliacao)
  if (objeto.respostas) {
    if (objeto.isAdd === true) {
      await axios.post(`${API_BASE_URL}api/grupoperguntasmedico/create`, objeto.respostas.data)
      await dispatch(updateAlert({ text: "Avaliação cadastrada com sucesso", variant: "success" }))
      await dispatch(setAlertTrigger(true))
      await dispatch(getData(getState()))
    } else {
      await axios.put(`${API_BASE_URL}api/respostamedico/update`, objeto.respostas)
      await dispatch(getData(getState()))
      await dispatch(updateAlert({ text: "Alterações salvas com sucesso!", variant: "success" }))
      await dispatch(setAlertTrigger(true))
    }
  }

  return paciente
})

export const getAvaliacaoPacienteDetail = createAsyncThunk('appUsers/getAvaliacaoPacienteDetail', async (avaliacaoId) => {
  const licenca = JSON.parse(localStorage.getItem("userData")) 

  const response = await axios.get(`${API_BASE_URL}api/avaliacaopaciente/search?search=${avaliacaoId}&clientId=${licenca.clientId}`)
  return response.data.avaliacao_paciente[0]
})

export const getAvaliacaoPacienteView = createAsyncThunk('appUsers/getAvaliacaoPacienteView', async (avaliacaoId) => {
  const licenca = JSON.parse(localStorage.getItem("userData")) 

  const response = await axios.get(`${API_BASE_URL}api/avaliacaopaciente/search?search=${avaliacaoId}&clientId=${licenca.clientId}`)
  return response.data.avaliacao_paciente[0]
})

export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    uuidAvaliacao: '',
    pacientes: [],
    modelos: [],
    total: 1,
    cadeirasAtivas: 0,
    modal: false,
    params: {},
    selected: {},
    allData: [],
    modeloDetail: null,
    avaliacaoDetail: null,
    avaliacaoView: null,
    selectedUser: null,
    currentStep: {
      step: 1,
      data: {}
    },
    alertProps: { text: "", variant: "" },
    alertTrigger: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(modalOpen.fulfilled, (state, action) => {
        state.modal = action.payload
      })
      .addCase(confereAtivos.fulfilled, (state, action) => {
        state.cadeirasAtivas = action.payload.data
      })
      .addCase(addAvaliaçãoPaciente.fulfilled, (state, action) => {
        state.uuidAvaliacao = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params 
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
      .addCase(updateAlert.fulfilled, (state, action) => {
        state.alertProps = action.payload
      })
      .addCase(setAlertTrigger.fulfilled, (state, action) => {
        state.alertTrigger = action.payload
      })
      .addCase(getAvaliacaoSelected.fulfilled, (state, action) => {
        state.selected = action.payload
      })
      .addCase(updateCurrentStep.fulfilled, (state, action) => {
        state.currentStep = action.payload
      })
      .addCase(getPacientes.fulfilled, (state, action) => {
        state.pacientes = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getModelos.fulfilled, (state, action) => {
        state.modelos = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getModeloDetail.fulfilled || getModeloDetail.rejected, (state, action) => {
        state.modeloDetail = action.payload
      })
      .addCase(getAvaliacaoPacienteDetail.fulfilled || getAvaliacaoPacienteDetail.rejected, (state, action) => {
        state.avaliacaoDetail = action.payload
      })
      .addCase(getAvaliacaoPacienteView.fulfilled || getAvaliacaoPacienteView.rejected, (state, action) => {
        state.avaliacaoView = action.payload
      })
  }
})

export default appUsersSlice.reducer
