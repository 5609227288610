// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/services/api'
const API_BASE_URL = `${process.env.REACT_APP_API_URL}`

export const getAllData = createAsyncThunk('appUsers/getAllData', async () => {
  const response = await axios.get('/api/users/list/all-data')
  return response.data
})

export const getData = createAsyncThunk('appUsers/getData', async params => {
  const licenca = JSON.parse(localStorage.getItem("userData"))

  const response = await axios.get(`${API_BASE_URL}api/perfilusuariomedico/list?offset=${params?.page}&limit=${params?.perPage}&clientId=${licenca.clientId}&orderBy=nome::asc`)
  return {
    params,
    data: response?.data?.perfil_usuario_medico,
    totalPages: response.data.ordenate.totalItem
  }
})

export const updateAlert = createAsyncThunk('appUsers/updateAlert', async (props, { dispatch, getState }) => {
  return {text:props.text, variant:props.variant}
})

export const setAlertTrigger = createAsyncThunk('appUsers/setAlertTrigger', async (toggle, { dispatch, getState }) => {
  return toggle
})

export const updateUserProfile = createAsyncThunk('appUserProfile/updateUserProfile', async (userProfile, { dispatch, getState }) => {
  //Atualizando no backEnd - "perfil_usuario"
  await axios.put(`${API_BASE_URL}api/perfilusuariomedico/update?uuid=${userProfile?.perfilUsuario?.uuid}`, {
    nome: userProfile.perfilUsuario.nome,
    status: userProfile.perfilUsuario.status
  })

  //Atualizando no backEnd - "perfil_permissoes"
  await axios.put(`${API_BASE_URL}api/perfilpermissaomedico/update`, userProfile.perfilPermissoes)

  await dispatch(updateAlert({ text: "Alterações salvas com sucesso!", variant: "success" }))
  await dispatch(setAlertTrigger(true))
  await dispatch(getData(getState()))
  return userProfile
})

export const updateCurrentRegister = createAsyncThunk('appUserProfile/updateCurrentRegister', async (currentUserProfile, { dispatch, getState }) => {
  return currentUserProfile
})

export const addUserProfile = createAsyncThunk('appUserProfile/addUserProfile', async (userProfile, { dispatch, getState }) => {
  await axios.post(`${API_BASE_URL}api/perfilusuariomedico/create`, userProfile)
  await dispatch(updateAlert({ text: "Perfil cadastrado com sucesso!", variant: "success" }))
  await dispatch(setAlertTrigger(true))
  await dispatch(getData(getState()))
  return profile
})

export const getCurrentRegister = createAsyncThunk('appUserProfile/getCurrentRegister', async params => {
  const licenca = JSON.parse(localStorage.getItem("userData"))

  const response = await axios.get(`${API_BASE_URL}api/perfilusuariomedico/search?search=${params.uuid}&clientId=${licenca.clientId}`)
  return {
    params,
    data: response.data.perfil_usuario_medico,
    totalPages: response.data.ordenate.totalPage
  }
})

export const getAllLimits = createAsyncThunk('appUserProfile/getAllLimits', async params => {
  const response = await axios.get(`${API_BASE_URL}api/perfillimitemedico/list?offset=0&limit=100`, {
    withCredentials: true
  })
  return response?.data?.perfil_limite_medico
})

export const getUser = createAsyncThunk('appUsers/getUser', async id => {
  const response = await axios.get('/api/users/user', { id })
  return response.data.user
})

export const updateStatus = createAsyncThunk('appUserProfile/updateStatus', async (params, { dispatch, getState }) => {
  const response = await axios.put(`${API_BASE_URL}api/perfilusuariomedico/update?uuid=${params.uuid}`, {
    status:!params.status
  })

  await dispatch(getData(getState()))
  return params
})

export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

export const deleteUserProfile = createAsyncThunk('appUsers/deleteUserProfile', async (uuid, { dispatch, getState }) => {
  await axios.put(`${API_BASE_URL}api/perfilusuariomedico/delete?uuid=${uuid}`)

  await dispatch(updateAlert({text:"Perfil de Usuario excluido com sucesso", variant:"danger"}))
  await dispatch(setAlertTrigger(true))

  await dispatch(getData(getState()))
})

export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    alertProps:{text:"", variant:""},
    alertTrigger:false,
    limits: [],
    currentRegisterSelected: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
      .addCase(updateAlert.fulfilled, (state, action) => {
        state.alertProps = action.payload
      })
      .addCase(setAlertTrigger.fulfilled, (state, action) => {
        state.alertTrigger = action.payload
      })
      .addCase(getAllLimits.fulfilled, (state, action) => {
        state.limits = action.payload
      })
      .addCase(getCurrentRegister.fulfilled, (state, action) => {
        state.currentRegisterSelected = action.payload
      })
      .addCase(updateCurrentRegister.fulfilled, (state, action) => {
        state.currentRegisterSelected = action.payload
      })
  }
})

export default appUsersSlice.reducer
