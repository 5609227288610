// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/services/api'
const API_BASE_URL = `${process.env.REACT_APP_API_URL}`

export const getAllData = createAsyncThunk('appUsers/getAllData', async () => {
  const response = await axios.get('/api/users/list/all-data')
  return response.data
})

export const getData = createAsyncThunk('appUsers/getData', async params => {
  const licenca = JSON.parse(localStorage.getItem("userData"))

  const response = await axios.get(`${API_BASE_URL}api/reabilitacaopaciente/list?offset=${params?.page}&clientId=${licenca.clientId}&limit=${params?.perPage}&orderBy=dataInicial::asc`)
  return {
    params,
    data: response?.data?.reabilitacao_paciente,
    totalPages: response.data.ordenate.totalItem
  }
})

export const modalOpen = createAsyncThunk('appUsers/modalOpen', async params => {
  return params
})

export const confereAtivos = createAsyncThunk('appUsers/confereAtivos', async params => {
  const licenca = JSON.parse(localStorage.getItem("userData"))

  const response = await axios.get(`${API_BASE_URL}api/reabilitacaopaciente/confereAtivos?clientId=${licenca.clientId}`)
  return {
    params,
    data: response?.data?.reabilitacao_paciente_aggregate?.aggregate?.count
  }
})

export const updateAlert = createAsyncThunk('appUsers/updateAlert', async (props, { dispatch, getState }) => {
  return {text:props.text, variant:props.variant}
})

export const setAlertTrigger = createAsyncThunk('appUsers/setAlertTrigger', async (toggle, { dispatch, getState }) => {
  return toggle
})

export const getUser = createAsyncThunk('appUsers/getUser', async id => {
  const response = await axios.get('/api/users/user', { id })
  return response.data.user
})

export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

export const searchRehabByPatientName = createAsyncThunk('appUsers/searchRehabByPatientName', async (patientName) => {
  const licenca = JSON.parse(localStorage.getItem("userData"))
  const res = await axios.get(`${API_BASE_URL}api/reabilitacaopaciente/searchRehabByPatientName?clientId=${licenca.clientId}&search=${patientName}`)
  console.log('res searchRehabByPatientName:', res)
  return res?.data?.reabilitacao_paciente
})

export const reiniciarReabilitacao = createAsyncThunk('appUsers/reiniciarReabilitacao', async (uuid, { dispatch, getState }) => {
  await axios.put(`${API_BASE_URL}api/reabilitacaopaciente/restart?uuid=${uuid}`)
  await dispatch(getData(getState()))
})

export const deleteReabilitacao = createAsyncThunk('appUsers/deleteReabilitacao', async (uuid, { dispatch, getState }) => {
  await axios.put(`${API_BASE_URL}api/reabilitacaopaciente/delete?uuid=${uuid}`)

  await dispatch(updateAlert({text:"Reabilitação excluida com sucesso", variant:"danger"}))
  await dispatch(setAlertTrigger(true))

  await dispatch(getData(getState()))
})

export const updateCurrentStep = createAsyncThunk('appUsers/updateCurrentStep', async (currentStep, { dispatch, getState }) => {
  return currentStep
})

export const updateCurrentStepGalery = createAsyncThunk('appUsers/updateCurrentStepGalery', async (currentStepGalery, { dispatch, getState }) => {
  return currentStepGalery
})

export const getPacientes = createAsyncThunk('appUsers/getPacientes', async params => {
  const licenca = JSON.parse(localStorage.getItem("userData"))

  const response = await axios.get(`${API_BASE_URL}api/paciente/listdropdown?clientId=${licenca.clientId}&orderBy=nome::asc`, {
    withCredentials: true
  })
  return {
    params,
    data: response.data.paciente,
    totalPages: response.data.ordenate.totalPage
  }
})

export const getModelos = createAsyncThunk('appUsers/getModelos', async params => {
  const response = await axios.get(`${API_BASE_URL}api/modeloreabilitacao/list?offset=0&limit=1000&orderBy=nome::asc`, {
    withCredentials: true
  })
  return {
    params,
    data: response.data.modelo_reabilitacao,
    totalPages: response.data.ordenate.totalPage
  }
})

export const getModelosGalery = createAsyncThunk('appUsers/getModelosGalery', async params => {
  const response = await axios.get(`${API_BASE_URL}api/modeloreabilitacao/listGalery?search=${params}&offset=0&limit=1000&orderBy=nome::asc`, {
    withCredentials: true
  })

  return {
    params,
    data: response.data.modelo,
    totalPages: response.data.ordenate.totalPage
  }
})

export const filter = createAsyncThunk('appUsers/filter', async filtro => {
  let searchTerm = ``
  if ((filtro?.categoriaFiltro) || (filtro?.grupoFiltro) || (filtro?.habilidadeFiltro) || (filtro?.nomeVideoFiltro) || (filtro?.modelo)) {
    if (filtro?.modelo) {
      searchTerm += `&modelo=${filtro?.modelo}`
    }
    if (filtro?.categoriaFiltro) {
      searchTerm += `&categoria=${filtro?.categoriaFiltro}`
    }
    if (filtro?.grupoFiltro) {
      searchTerm += `&grupo=${filtro?.grupoFiltro}`
    }
    if (filtro?.habilidadeFiltro) {
      searchTerm += `&habilidade=${filtro?.habilidadeFiltro}`
    }
    if (filtro?.nomeVideoFiltro) {
      searchTerm += `&nome=${filtro?.nomeVideoFiltro}`
    }
  }
  const response = await axios.get(`${API_BASE_URL}api/pergunta/filtroGaleria?offset=0&limit=1000&orderBy=nomeVideo::asc${searchTerm}`, {
    withCredentials: true
  })

  return {
    perguntas: response.data.perguntas,
    categoria: response.data.categoria,
    grupo: response.data.grupo,
    habilidade: response.data.habilidade,
    nome: response.data.nome
  }
})

export const getModelo = createAsyncThunk('appUsers/getModelo', async uuid => {
  const response = await axios.get(`${API_BASE_URL}api/modeloreabilitacao/search?search=${uuid}&offset=0&limit=10&orderBy=nome::asc`, {
    withCredentials: true
  })
  const data = response.data.modelo_reabilitacao[0]
  return data
})

export const getModeloEdit = createAsyncThunk('appUsers/getModeloEdit', async uuid => {
  const licenca = JSON.parse(localStorage.getItem("userData"))

  const response = await axios.get(`${API_BASE_URL}api/reabilitacaopaciente/search?search=${uuid}&clientId=${licenca.clientId}`, {
    withCredentials: true
  })

  const data = response.data.reabilitacao_paciente[0]

  return data
})

export const getRehabView = createAsyncThunk('appUsers/getRehabView', async uuid => {
  const licenca = JSON.parse(localStorage.getItem("userData"))

  const response = await axios.get(`${API_BASE_URL}api/reabilitacaopaciente/respostapaciente?search=${uuid}&clientId=${licenca.clientId}`, {
    withCredentials: true
  })
  const data = response.data.reabilitacao_paciente[0]
  return data
})

export const getCurrentPaciente = createAsyncThunk('appUsers/getCurrentPaciente', async uuid => {
  const licenca = JSON.parse(localStorage.getItem("userData"))

  const response = await axios.get(`${API_BASE_URL}api/paciente/search?search=${uuid}&clientId=${licenca.clientId}`, {
    withCredentials: true
  })
  return {
    data: response.data.paciente
  }
})

export const addReabilitacaoPaciente = createAsyncThunk('appUsers/addReabilitacaoPaciente', async (reabilitacao, { dispatch, getState }) => {
  const response = await axios.post(`${API_BASE_URL}api/reabilitacaopaciente/create`, reabilitacao)

  await dispatch(updateAlert({ text: "Reabilitação cadastrada com sucesso", variant: "success" }))
  await dispatch(setAlertTrigger(true))
  await dispatch(getData(getState()))
  return paciente
})

export const updateReabilitacao = createAsyncThunk('appReabilitacao/updateReabilitacao', async (reabilitacao, { dispatch, getState }) => {
  const uuid = reabilitacao.uuid
  delete reabilitacao.uuid
  await axios.put(`${API_BASE_URL}api/reabilitacaopaciente/update?uuid=${uuid}`, reabilitacao)


  await dispatch(getData(getState()))

  await dispatch(updateAlert({ text: "Alterações salvas com sucesso!", variant: "success" }))
  await dispatch(setAlertTrigger(true))

  return paciente
})

export const updateFasesReabilitacao = createAsyncThunk('appReabilitacao/updateFasesReabilitacao', async (data, { dispatch, getState }) => {
  const {rehabId, fases} = data
  const result = await axios.put(`${API_BASE_URL}api/fase/update?rehabId=${rehabId}`, fases)

  await dispatch(getData(getState()))

  return result
})

export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    pacientes: [],
    modelos: [],
    modelosGalery: [],
    cadeirasAtivas: 0,
    total: 1,
    modal: false,
    params: {},
    allData: [],
    filtro: [],
    categoria: [],
    grupo: [],
    habilidade: [],
    nome: [],
    reabilitacaoDetail: {},
    reabilitacaoEdit: {},
    reabilitacaoView: {},
    selectedUser: null,
    currentStepGalery: {
      step: 1,
      data: {}
    },
    currentStep: {
      step: 1,
      data: {}
    },
    pacienteSelected: null,
    alertProps:{text:"", variant:""},
    alertTrigger:false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(filter.fulfilled, (state, action) => {
        state.filtro = action.payload.perguntas
        state.categoria = action.payload.categoria
        state.grupo = action.payload.grupo
        state.habilidade = action.payload.habilidade
        state.nome = action.payload.nome
      })
      .addCase(confereAtivos.fulfilled, (state, action) => {
        state.cadeirasAtivas = action.payload.data
      })
      .addCase(modalOpen.fulfilled, (state, action) => {
        state.modal = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(updateCurrentStep.fulfilled, (state, action) => {
        state.currentStep = action.payload
      })
      .addCase(updateCurrentStepGalery.fulfilled, (state, action) => {
        state.currentStepGalery = action.payload
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
      .addCase(updateAlert.fulfilled, (state, action) => {
        state.alertProps = action.payload
      })
      .addCase(setAlertTrigger.fulfilled, (state, action) => {
        state.alertTrigger = action.payload
      })
      .addCase(getPacientes.fulfilled, (state, action) => {
        state.pacientes = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getModelos.fulfilled, (state, action) => {
        state.modelos = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getModelosGalery.fulfilled, (state, action) => {
        state.modelosGalery = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getModelo.fulfilled || getModelo.rejected, (state, action) => {
        state.reabilitacaoDetail = action.payload
      })
      .addCase(getModeloEdit.fulfilled || getModeloEdit.rejected, (state, action) => {
        state.reabilitacaoEdit = action.payload
      })
      .addCase(getRehabView.fulfilled || getRehabView.rejected, (state, action) => {
        state.reabilitacaoView = action.payload
      })
      .addCase(getCurrentPaciente.fulfilled, (state, action) => {
        state.pacienteSelected = action.payload
      })
  }
})

export default appUsersSlice.reducer
