// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import todo from '@src/views/apps/todo/store'
import chat from '@src/views/apps/chat/store'
import users from '@src/views/pages/users/store'
import paciente from '@src/views/pages/customers/store'
import avaliacao from '@src/views/pages/evaluations/store'
import perfilUsuario from '@src/views/pages/profiles/store'
import reabilitacao from '@src/views/pages/rehabilitations/store'
import updatePlan from '@src/views/pages/updatePlan/store'
import email from '@src/views/apps/email/store'
import invoice from '@src/views/apps/invoice/store'
import calendar from '@src/views/apps/calendar/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import dataTables from '@src/views/tables/data-tables/store'
import permissions from '@src/views/apps/roles-permissions/store'
import questionnaire from '@src/views/pages/questionnaires/store'
import myprofile from '@src/views/pages/my-profile/store'
import mensagem from '@src/views/dashboard/messages/store'
import home from '@src/views/dashboard/home/store'
import ticketsuporte from '@src/views/dashboard/tickets/store'
import mensagemPaciente from '@src/views/pages/customers/messages/store'

const rootReducer = {
  auth,
  todo,
  chat,
  email,
  users,
  paciente,
  avaliacao,
  perfilUsuario,
  reabilitacao,
  updatePlan,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  permissions,
  questionnaire,
  myprofile,
  mensagem,
  mensagemPaciente,
  ticketsuporte,
  home
}

export default rootReducer
