// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/services/api'
const API_BASE_URL = `${process.env.REACT_APP_API_URL}`

export const updateAlert = createAsyncThunk('mensagemDashboard/updateAlert', async (props, { dispatch, getState }) => {
  return {text:props.text, variant:props.variant}
})

export const setAlertTrigger = createAsyncThunk('mensagemDashboard/setAlertTrigger', async (toggle, { dispatch, getState }) => {
  return toggle
})

export const setPostMensagem = createAsyncThunk('mensagemDashboard/setPostMensagem', async (toggle, { dispatch, getState }) => {
  return toggle
})

export const getMails = createAsyncThunk('mensagemDashboard/getMails', async params => {
  const licenca = JSON.parse(localStorage.getItem("userData"))
  let response = []
  if (params.filter) {
    response = await axios.get(`${API_BASE_URL}api/conversa/filter?search=${params.filter}&limit=${params?.perPage}&offset=${params?.page}&medicoId=${licenca.uuid}&clientId=${licenca.clientId}`)
  } else {
    response = await axios.get(`${API_BASE_URL}api/conversa/list?medicoId=${licenca.uuid}&clientId=${licenca.clientId}&limit=${params?.perPage}&offset=${params?.page}`)
  }
  
  return {
    params,
    data: response.data.conversa,
    totalPages: response.data.ordenate.totalItem
  }
})

export const postMensagem = createAsyncThunk('mensagemDashboard/postMensagem', async obj => {
  const response = await axios.post(`${API_BASE_URL}api/mensagem/create`, obj)
  return response
})

export const postConversa = createAsyncThunk('mensagemDashboard/postConversa', async (obj) => {
  const response = await axios.post(`${API_BASE_URL}api/conversamultiplo/create`, obj)
  return response
})

export const testeDeleteMensagem = createAsyncThunk('mensagemDashboard/testeDeleteMensagem', async obj => {
  const response = await axios.put(`${API_BASE_URL}api/conversa/delete`, obj)
  return response
})

export const updateMails = createAsyncThunk(
  'appEmail/updateMails',
  async ({ emailIds, dataToUpdate }, { dispatch, getState }) => {
    
  }
)

export const updateMailLabel = createAsyncThunk(
  'appEmail/updateMailLabel',
  async ({ emailIds, label }, { dispatch, getState }) => {
    const response = await axios.post('/apps/email/update-emails-label', { emailIds, label })
    await dispatch(getMails(getState().email.params))
    return response.data
  }
)

export const paginateMail = createAsyncThunk('appEmail/paginateMail', async ({ dir, emailId }) => {
  const response = await axios.get('/apps/email/paginate-email', { params: { dir, emailId } })
  return response.data
})

export const selectCurrentMail = createAsyncThunk('mensagemDashboard/selectCurrentMail', async uuid => {
  const licenca = JSON.parse(localStorage.getItem("userData"))
  const response = await axios.get(`${API_BASE_URL}api/conversa/searchmedicodashboard?search=${uuid}&clientId=${licenca.clientId}`)
  return {
    data: response.data.conversa[0].mensagens
  }
})

export const appEmailSlice = createSlice({
  name: 'mensagemDashboard',
  initialState: {
    mails: [],
    params: {},
    emailsMeta: {},
    total: 1,
    params: {},
    selectedMails: [],
    currentMail: null,
    alertProps:{text:"", variant:""},
    alertTrigger:false,
    postToggle:false,
    testeDelete:null
  },
  reducers: {
    selectMail: (state, action) => {
      const selectedMails = state.selectedMails
      if (!selectedMails.includes(action.payload)) {
        selectedMails.push(action.payload)
      } else {
        selectedMails.splice(selectedMails.indexOf(action.payload), 1)
      }
      state.selectedMails = selectedMails
      
    },
    selectAllMail: (state, action) => {
      const selectAllMailsArr = []
      if (action.payload) {
        selectAllMailsArr.length = 0
        state.mails.forEach(mail => selectAllMailsArr.push(mail.uuid))
      } else {
        selectAllMailsArr.length = 0
      }
      state.selectedMails = selectAllMailsArr
    },
    resetSelectedMail: state => {
      state.selectedMails = []
    }
  }, 
  extraReducers: builder => {
    builder 
      .addCase(getMails.fulfilled, (state, action) => {
        state.params = action.payload.params 
        state.total = action.payload.totalPages
        state.mails = action.payload.data
      })
      .addCase(updateAlert.fulfilled, (state, action) => {
        state.alertProps = action.payload
      })
      .addCase(setAlertTrigger.fulfilled, (state, action) => {
        state.alertTrigger = action.payload
      })
      .addCase(setPostMensagem.fulfilled, (state, action) => {
        state.postToggle = action.payload
      })
      .addCase(updateMails.fulfilled, (state, action) => {
        function updateMailData(email) {
          Object.assign(email, action.payload.dataToUpdate)
        }
        state.mails.forEach(email => {
          if (action.payload.emailIds.includes(email.id)) {
            updateMailData(email)
          }
        })
      })
      .addCase(paginateMail.fulfilled, (state, action) => {
        const data = action.payload
        const dataIndex = state.mails.findIndex(i => i.id === data.id)
        dataIndex === 0 ? (data.hasPreviousMail = false) : (data.hasPreviousMail = true)
        dataIndex === state.mails.length - 1 ? (data.hasNextMail = false) : (data.hasNextMail = true)
        state.currentMail = data
      })
      .addCase(selectCurrentMail.fulfilled, (state, action) => {
        state.currentMail = action.payload
      })
  }
})

export const { selectMail, selectAllMail, resetSelectedMail } = appEmailSlice.actions

export default appEmailSlice.reducer
