// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/services/api'

export const setAlertTrigger = createAsyncThunk('appUpdatePlan/setAlertTrigger', async (toggle, { dispatch, getState }) => {
  return toggle
})

export const updateAlert = createAsyncThunk('appUpdatePlan/updateAlert', async (props, { dispatch, getState }) => {
  return { text: props.text, variant: props.variant }
})

export const getAdesao = createAsyncThunk('appUpdatePlan/getAdesao', async params => {
  const licenca = JSON.parse(localStorage.getItem("userData"))

  const response = await axios.get(`${process.env.REACT_APP_API_URL}api/adesoes/searchporlicenca?search=${licenca.clientId}`)
  const uso = response.data.avaliacao_paciente_aggregate.aggregate.count + response.data.reabilitacao_paciente_aggregate.aggregate.count
  return {
    params,
    data: response?.data?.adesoes,
    cadeiraUso: uso,
    totalPages: response.data.ordenate.totalItem
  }
})

export const appUpdatePlanSlice = createSlice({
  name: 'appUpdatePlan',
  alertProps: { text: "", variant: "" },
  alertTrigger: false,
  initialState: {
    planos: [],
    adesao: {},
    cadeirasUso: 0,
    total: 1,
    params: {},
    allData: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(updateAlert.fulfilled, (state, action) => {
        state.alertProps = action.payload
      })
      .addCase(setAlertTrigger.fulfilled, (state, action) => {
        state.alertTrigger = action.payload
      })
      .addCase(getAdesao.fulfilled, (state, action) => {
        state.adesao = action.payload.data
        state.total = action.payload.totalPages
        state.cadeirasUso = action.payload.cadeiraUso
      })
  }
})

export default appUpdatePlanSlice.reducer
