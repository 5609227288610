// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/services/api'
const API_BASE_URL = `${process.env.REACT_APP_API_URL}`

export const updateAlert = createAsyncThunk('appUsers/updateAlert', async (props, { dispatch, getState }) => {
  return {text:props.text, variant:props.variant}
})

export const setAlertTrigger = createAsyncThunk('appUsers/setAlertTrigger', async (toggle, { dispatch, getState }) => {
  return toggle
})

export const setPaciente = createAsyncThunk('mensagemGestao/setPaciente', async (paciente, { dispatch, getState }) => {
  return paciente
})

export const getMails = createAsyncThunk('mensagemGestao/getMails', async params => {
    const response = await axios.get(`${API_BASE_URL}api/mensagem/listmensagem?search=${params?.uuid}&limit=${params?.perPage}&offset=${params?.page}`)
    return {
      params,
      data: response.data.mensagem,
      totalPages: response.data.ordenate.totalItem
    }
})

export const updateMails = createAsyncThunk(
  'appEmail/updateMails',
  async ({ emailIds, dataToUpdate }, { dispatch, getState }) => {
   
  }
)

export const updateMailLabel = createAsyncThunk(
  'appEmail/updateMailLabel',
  async ({ emailIds, label }, { dispatch, getState }) => {
    const response = await axios.post('/apps/email/update-emails-label', { emailIds, label })
   
    return response.data
  }
)

export const paginateMail = createAsyncThunk('appEmail/paginateMail', async ({ dir, emailId }) => {
  const response = await axios.get('/apps/email/paginate-email', { params: { dir, emailId } })
  return response.data
})

export const selectCurrentMail = createAsyncThunk('mensagemGestao/selectCurrentMail', async uuid => {
  const response = await axios.get(`${API_BASE_URL}api/mensagem/search?search=${uuid}`)
    return {
      data: response.data.mensagem[0]
    }
})

export const testeDeleteMensagem = createAsyncThunk('mensagemGestao/testeDeleteMensagem', async obj => {
  const response = await axios.put(`${API_BASE_URL}api/mensagem/delete`, obj)
  return response
})

export const postMensagem = createAsyncThunk('mensagemGestao/postMensagem', async obj => {
  const response = await axios.post(`${API_BASE_URL}api/mensagem/create`, obj)
  return response
})

export const postConversa = createAsyncThunk('mensagemGestao/postConversa', async obj => {
  const response = await axios.post(`${API_BASE_URL}api/conversa/create`, obj)
  return response
})

export const appEmailSlice = createSlice({
  name: 'mensagemGestao',
  initialState: {
    mails: [],
    total: 1,
    params: {},
    emailsMeta: {},
    selectedMails: [],
    currentMail: null,
    alertProps:{text:"", variant:""},
    alertTrigger:false,
    currentPaciente: null
  },
  reducers: {
    selectMail: (state, action) => {
      const selectedMails = state.selectedMails
      if (!selectedMails.includes(action.payload)) {
        selectedMails.push(action.payload)
      } else {
        selectedMails.splice(selectedMails.indexOf(action.payload), 1)
      }
      state.selectedMails = selectedMails
    },
    selectAllMail: (state, action) => {
      const selectAllMailsArr = []
      if (action.payload) {
        selectAllMailsArr.length = 0
        state.mails.forEach(mail => selectAllMailsArr.push(mail.uuid))
      } else {
        selectAllMailsArr.length = 0
      }
      state.selectedMails = selectAllMailsArr
    },
    resetSelectedMail: state => {
      state.selectedMails = []
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getMails.fulfilled, (state, action) => {
        state.mails = action.payload.data
        state.params = action.payload.params 
        state.total = action.payload.totalPages
      })
      .addCase(updateAlert.fulfilled, (state, action) => {
        state.alertProps = action.payload
      })
      .addCase(setAlertTrigger.fulfilled, (state, action) => {
        state.alertTrigger = action.payload
      })
      .addCase(setPaciente.fulfilled, (state, action) => {
        state.currentPaciente = action.payload
      })
      .addCase(updateMails.fulfilled, (state, action) => {
        function updateMailData(email) {
          Object.assign(email, action.payload.dataToUpdate)
        }
        state.mails.forEach(email => {
          if (action.payload.emailIds.includes(email.id)) {
            updateMailData(email)
          }
        })
      })
      .addCase(paginateMail.fulfilled, (state, action) => {
        const data = action.payload
        const dataIndex = state.mails.findIndex(i => i.id === data.id)
        dataIndex === 0 ? (data.hasPreviousMail = false) : (data.hasPreviousMail = true)
        dataIndex === state.mails.length - 1 ? (data.hasNextMail = false) : (data.hasNextMail = true)
        state.currentMail = data
      })
      .addCase(selectCurrentMail.fulfilled, (state, action) => {
        state.currentMail = action.payload
      })
  }
})

export const { selectMail, selectAllMail, resetSelectedMail } = appEmailSlice.actions

export default appEmailSlice.reducer
