// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/services/api'
import { getFormData } from '../../../../utility/Utils'

export const getCurrentUser = createAsyncThunk('appMyProfile/getCurrentUser', async params => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}api/userclient/searchClient?offset=0&limit=10&orderBy=nome::asc&search=${params.uuid}`)

  return {
    params,
    data: response.data.usuarios_cliente[0],
    totalPages: response.data.ordenate.totalPage
  }
})

export const setAlertTrigger = createAsyncThunk('appMyProfile/setAlertTrigger', async (toggle, { dispatch, getState }) => {
  return toggle
})

export const updateAlert = createAsyncThunk('appMyProfile/updateAlert', async (props, { dispatch, getState }) => {
  return { text: props.text, variant: props.variant }
})

export const updateCurrentUser = createAsyncThunk('appMyProfile/updateCurrentUser', async (currentUser, { dispatch, getState }) => {
  return currentUser
})

export const updateAccession = createAsyncThunk('appUserProfile/updateAccession', async (params, { dispatch, getState }) => {
  await axios.put(`${process.env.REACT_APP_API_URL}api/clientes/update?uuid=${params.uuid}`, {
    planoId: params?.planoId
  })
  return params
})

export const updateAccreditations = createAsyncThunk('appMyProfile/updateAccreditations', async (accreditations, { dispatch, getState }) => {
  //Atualizando no backEnd - "credenciamentos"
  const response = await axios.put(`${process.env.REACT_APP_API_URL}api/credenciamento/update`, accreditations)
})

export const updateUser = createAsyncThunk('appMyProfile/updateUser', async (user, { dispatch, getState }) => {
  //Atualizando no backEnd - "usuário cliente"
  const response = await axios.put(`${process.env.REACT_APP_API_URL}api/userclient/update?uuid=${user.uuid}`, getFormData(user.fields))

  if (response?.data?.update_usuarios_cliente_by_pk?.uuid) {
    await dispatch(getCurrentUser(user))
  }
  return user
})

export const updateUserBilling = createAsyncThunk('appMyProfile/updateUserBilling', async (user, { dispatch, getState }) => {
  //Atualizando no backEnd - "usuário cliente"
  await axios.put(`${process.env.REACT_APP_API_URL}api/userclient/update?uuid=${user.uuid}`, user.faturamento)

  //Atualizando endereco - "enderecos"
  const response = await axios.post(`${process.env.REACT_APP_API_URL}api/enderecos/update`, user.endereco_cobranca)

  if (response?.data?.update_usuarios_cliente_by_pk?.uuid) {
    await dispatch(getCurrentUser(user))
  }
  return user
})

export const updateAddress = createAsyncThunk('appMyProfile/updateUser', async (address, { dispatch, getState }) => {
  //Atualizando no backEnd - "enderecos"
  await axios.post(`${process.env.REACT_APP_API_URL}api/enderecos/update?uuid=${address.uuid}`, address.endereco)

  return address
})

export const appMyProfileSlice = createSlice({
  name: 'appMyProfile',
  alertProps: { text: "", variant: "" },
  alertTrigger: false,
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    fotoPerfil: "",
    currentUser: {
      step: 1,
      data: {}
    }
  },

  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(updateCurrentUser.fulfilled, (state, action) => {
        state.currentUser = action.payload
      })
      .addCase(updateAlert.fulfilled, (state, action) => {
        state.alertProps = action.payload
      })
      .addCase(setAlertTrigger.fulfilled, (state, action) => {
        state.alertTrigger = action.payload
      })
      .addCase(getCurrentUser.fulfilled, (state, action) => {
        state.currentUser = action.payload
      })
  }
})

export default appMyProfileSlice.reducer
