// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/services/api'
const API_BASE_URL = `${process.env.REACT_APP_API_URL}`

export const updateAlert = createAsyncThunk('appUsers/updateAlert', async (props, { dispatch, getState }) => {
  return {text:props.text, variant:props.variant}
})

export const setAlertTrigger = createAsyncThunk('appUsers/setAlertTrigger', async (toggle, { dispatch, getState }) => {
  return toggle
})

export const getGestaoPacienteAvaliacao = createAsyncThunk('appUsers/getGestaoPacienteAvaliacao', async paciente => {
  const response = await axios.get(`${API_BASE_URL}api/gestaopaciente/avaliacao?search=${paciente.uuid}`, {
    withCredentials: true
  })

  return response.data.avaliacao_paciente
})

export const getInteracaoPaciente = createAsyncThunk('appUsers/getInteracaoPaciente', async params => {
  const { id, limit, offset } = params
  const queryParams = new URLSearchParams({ patientId: id })
  
  if ('limit' in params && 'offset' in params) {
    queryParams.append('limit', limit)
    queryParams.append('offset', offset)
  }
  
  const { data } = await axios.get(`${API_BASE_URL}api/interacaopaciente/search?${queryParams.toString()}`, {
    withCredentials: true
  })
  return data
})

export const deleteAvaliacao = createAsyncThunk('appUsers/deleteAvaliacao', async (avaliacao, { dispatch, getState }) => {
  await axios.put(`${API_BASE_URL}api/avaliacaopaciente/delete?uuid=${avaliacao.uuid}`)

    await dispatch(updateAlert({text:"Avaliação excluida com sucesso", variant:"danger"}))
    await dispatch(setAlertTrigger(true))
    await dispatch(getGestaoPacienteAvaliacao({uuid: avaliacao.pacienteUuid}))
})

export const getAllData = createAsyncThunk('appUsers/getAllData', async () => {
  const response = await axios.get('/api/users/list/all-data')
  return response.data
})

export const getData = createAsyncThunk('appUsers/getData', async params => {
  const licenca = JSON.parse(localStorage.getItem("userData"))
  const response = await axios.get(`${API_BASE_URL}api/paciente/list?offset=0&limit=1000&clientId=${licenca.clientId}&orderBy=nome::asc`)
  return {
    params,
    data: response.data.users,
    totalPages: response.data.ordenate.totalItem
  }
})

export const updateCurrentStep = createAsyncThunk('appUsers/updateCurrentStep', async (currentStep, { dispatch, getState }) => {
  return currentStep
})

export const getCurrentPaciente = createAsyncThunk('appUsers/getCurrentPaciente', async params => {
  const licenca = JSON.parse(localStorage.getItem("userData"))

  const response = await axios.get(`${API_BASE_URL}api/paciente/search?search=${params.uuid}&clientId=${licenca.clientId}`, {
    withCredentials: true
  })
  return {
    params,
    data: response.data.paciente,
    totalPages: response.data.ordenate.totalPage
  }
})

export const getGestaoPaciente = createAsyncThunk('appUsers/getGestaoPaciente', async paciente => {
  const response = await axios.get(`${API_BASE_URL}api/gestaopaciente/search?search=${paciente.uuid}`, {
    withCredentials: true
  })

  return response.data
})

export const getUser = createAsyncThunk('appUsers/getUser', async id => {
  const response = await axios.get('/api/users/user', { id })
  return response.data.user
})

export const updatePaciente = createAsyncThunk('appPaciente/updatePaciente', async (paciente, { dispatch, getState }) => {
  const uuid = paciente.uuid
  delete paciente.uuid
  const response = await axios.put(`${API_BASE_URL}api/paciente/updatemedico?uuid=${uuid}`, paciente)


 /*  await dispatch(getData(getState()))

  await dispatch(updateAlert({ text: "Alterações salvas com sucesso!", variant: "success" }))
  await dispatch(setAlertTrigger(true)) */

  return response
})

export const bloquearAcessoPaciente =  createAsyncThunk('appPaciente/updatePaciente', async (data, { dispatch, getState }) => {
  const uuid = data.uuid
  delete data.uuid
  const res = await axios.put(`${API_BASE_URL}api/paciente/updatemedico?uuid=${uuid}`, data)

  return res
})

export const updateGestao = createAsyncThunk('appPaciente/updateGestao', async (paciente, { dispatch, getState }) => {
  const uuid = paciente.uuid
  delete paciente.uuid
  await axios.put(`${API_BASE_URL}api/gestaopaciente/update?uuid=${uuid}`, paciente)


  await dispatch(getData(getState()))

  await dispatch(updateAlert({ text: "Alterações salvas com sucesso!", variant: "success" }))
  await dispatch(setAlertTrigger(true))

  return paciente
})

export const getVerificaNomeUsuarioPaciente = createAsyncThunk('appUsers/getVerificaNomeUsuarioPaciente', async (username) => {
  const response = await axios.get(`${API_BASE_URL}api/paciente/procuranomeusuario?search=${username}`)
  return response.data.ordenate.totalItem
})

export const getVerificaEmailPaciente = createAsyncThunk('appUsers/getVerificaEmailPaciente', async (email) => {
  const response = await axios.get(`${API_BASE_URL}api/paciente/procuraemail?search=${email}`)
  return response.data.ordenate.totalItem
})

export const getVerificaNomeUsuarioUpdatePaciente = createAsyncThunk('appUsers/getVerificaNomeUsuarioUpdatePaciente', async (params) => {
  const response = await axios.get(`${API_BASE_URL}api/paciente/procuranomeusuarioupdate?search=${params.username}&uuid=${params.uuid}`)
  return response.data.ordenate.totalItem
})

export const getVerificaEmailUpdatePaciente = createAsyncThunk('appUsers/getVerificaEmailUpdatePaciente', async (params) => {
  const response = await axios.get(`${API_BASE_URL}api/paciente/procuraemailupdate?search=${params.email}&uuid=${params.uuid}`)
  return response.data.ordenate.totalItem
})

export const updateStatus = createAsyncThunk('appPaciente/updateStatus', async (params, { dispatch, getState }) => {
  const response = await axios.put(`${API_BASE_URL}api/paciente/updatemedico?uuid=${params.uuid}`, {
    status:!params.status
  })

  await dispatch(updateAlert({ text: "Alterações salvas com sucesso!", variant: "success" }))
  await dispatch(setAlertTrigger(true))
  await dispatch(getData(getState()))
  return params
})

export const addPaciente = createAsyncThunk('appUsers/addPaciente', async (paciente, { dispatch, getState }) => {
    await axios.post(`${API_BASE_URL}api/paciente/create`, paciente)
    /* const response = await axios.post(`${API_BASE_URL}api/paciente/create`, paciente)
    return response */
    /* await dispatch(getData(getState()))
    dispatch(updateAlert({ text: "Paciente cadastrado com sucesso", variant: "success" }))
    dispatch(setAlertTrigger(true))


    dispatch(updateAlert({ text: "Erro ao cadastrar paciente, tente novamente mais tarde", variant: "danger" })) */

  /* const response = await axios.post(`${API_BASE_URL}api/paciente/create`, paciente)
  await dispatch(updateAlert({ text: "Paciente cadastrado com sucesso", variant: "success" }))
  await dispatch(setAlertTrigger(true))
  await dispatch(getData(getState()))
  return response */
})

export const addAddress = createAsyncThunk('appAccessions/addAddress', async (address, { dispatch, getState }) => {
  const response = await axios.post(`${API_BASE_URL}api/enderecos/create`, address)
  return address
})

export const deletePaciente = createAsyncThunk('appUsers/deletePaciente', async (uuid, { dispatch, getState }) => {
  await axios.put(`${API_BASE_URL}api/paciente/delete?uuid=${uuid}`)
    await dispatch(getData(getState()))

    await dispatch(updateAlert({text:"Paciente excluido com sucesso", variant:"danger"}))
    await dispatch(setAlertTrigger(true))
})

export const updateAddress = createAsyncThunk('appAccessions/updateAddress', async (address, { dispatch, getState }) => {
  const uuid = address.uuid
  delete address.uuid
  const response = await axios.put(`${API_BASE_URL}api/enderecos/update?uuid=${uuid}`, address)

  await dispatch(updateAlert({ text: "Alterações salvas com sucesso!", variant: "success" }))
  await dispatch(setAlertTrigger(true))
  await dispatch(getData(getState()))
  return address
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    pacienteSelected: null,
    emailExiste: 0,
    emailUpdateExiste: 0,
    nomeUsuarioExiste: 0,
    nomeUsuarioUpdateExiste: 0,
    gestaoSelected: null,
    avaliacoesGestao: [],
    selectedUser: null,
    currentStep: {
      step: 1,
      data: {}
    },
    alertProps:{text:"", variant:""},
    alertTrigger:false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getCurrentPaciente.fulfilled, (state, action) => {
        state.pacienteSelected = action.payload
      })
      .addCase(getGestaoPaciente.fulfilled, (state, action) => {
        state.gestaoSelected = action.payload
      })
      .addCase(getGestaoPacienteAvaliacao.fulfilled, (state, action) => {
        state.avaliacoesGestao = action.payload
      })
      .addCase(updateCurrentStep.fulfilled, (state, action) => {
        state.currentStep = action.payload
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
      .addCase(updateAlert.fulfilled, (state, action) => {
        state.alertProps = action.payload
      })
      .addCase(setAlertTrigger.fulfilled, (state, action) => {
        state.alertTrigger = action.payload
      })
      .addCase(getVerificaEmailPaciente.fulfilled || getVerificaEmailPaciente.rejected, (state, action) => {
        state.emailExiste = action.payload
      })
      .addCase(getVerificaEmailUpdatePaciente.fulfilled || getVerificaEmailUpdatePaciente.rejected, (state, action) => {
        state.emailUpdateExiste = action.payload
      })
      .addCase(getVerificaNomeUsuarioPaciente.fulfilled || getVerificaNomeUsuarioPaciente.rejected, (state, action) => {
        state.nomeUsuarioExiste = action.payload
      })
      .addCase(getVerificaNomeUsuarioUpdatePaciente.fulfilled || getVerificaNomeUsuarioUpdatePaciente.rejected, (state, action) => {
        state.nomeUsuarioUpdateExiste = action.payload
      })
  }
})

export default appUsersSlice.reducer
