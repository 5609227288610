// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

export const maskPhone = (v) => {
  v = v.replace(/\D/g, "")             //Remove tudo o que não é dígito
  v = v.replace(/^(\d{2})(\d)/g, "($1) $2") //Coloca parênteses em volta dos dois primeiros dígitos
  v = v.replace(/(\d)(\d{4})$/, "$1-$2")
  return v

}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return '/access-control'
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#316E5C1a', // for option hover bg-color
    primary: '#316E5C', // for selected option bg-color
    neutral10: '#316E5C', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

export const getAddress = async (cep) => {
  try {
    const url = `https://viacep.com.br/ws/${cep}/json/`
    const options = {
      method: "GET",
      mode: "cors",
      headers: {
        "content-type": "application/json;charset=utf-8"
      }
    }
    const result = await fetch(url, options)
    const endereco = await result.json()
    return endereco
  } catch (e) {
    return null
  }
}

function buildFormData(formData, data, parentKey) {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach(key => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
    })
  } else {
    const value = data === null ? '' : data

    if (value) {
      formData.append(parentKey, value)
    }
  }
}

export const getFormData = (data) => {
  const formData = new FormData()
  buildFormData(formData, data)
  return formData
}

export const removeSpacesAllString = (str) => {
  return str.trim().replace(/\s/g, '')
}

export const maskValues = (v) => {
  v = v.replace(/\D/g, "")
  v = v.replace(/([0-9]{2})$/g, ",$1")
  if (v.length > 6) v = v.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2")

  return v
}

export const fetchImageUtil = async (url) => {
  if (!url) return null

  const fullUrl = `${process.env.REACT_APP_API_URL}${url}`

  try {
    const res = await fetch(fullUrl)

    if (!res.ok) return null

    const imgBlob = await res.blob()
    const imgObjectURL = URL.createObjectURL(imgBlob)

    return imgObjectURL
  } catch (err) {
    console.error('Error in fetchImageUtil:', err)
    return null
  }
}

export const localDateToJSObjectDate = (str) => {
  const [day, month, year] = str.split('/').map(Number)

  const formattedDate = new Date(year, month - 1, day)
  return formattedDate
}

export const emailValidator = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(email)

  
}

export const formatDuration = (ms) => {
  const totalSeconds = Math.floor(ms / 1000)
    const hours = Math.floor(totalSeconds / 3600)
    const minutes = Math.floor((totalSeconds % 3600) / 60)
    const seconds = totalSeconds % 60

    let formattedDuration = ""
    if (hours > 0) {
        formattedDuration += `${hours  }H `
    }
    if (minutes > 0) {
        formattedDuration += `${minutes  }min `
    }
    if (seconds > 0 || formattedDuration === "") {
        formattedDuration += `${seconds  }s`
    }
    
    return formattedDuration.trim()

}
